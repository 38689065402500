<template>
  <v-app>
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawer"
      app
      clipped
      :touchless="!drawer"
      class="app--navigation-drawer"
    >
      <div
        v-if="$vuetify.breakpoint.mdAndDown"
        class="d-flex align-center px-1 primary"
      >
        <v-btn
          v-if="authenticated"
          icon
          height="48"
          width="48"
          class="ml-0"
          color="secondary"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            mdi-menu
          </v-icon>
        </v-btn>
        <v-btn
          text
          tile
          x-large
          class="px-3 mx-1 w-100 d-flex justify-start primary"
          :to="{ name: 'home' }"
          @click="drawer = false"
        >
          <v-toolbar-title class="text-none">
            BlackLynx App
          </v-toolbar-title>
        </v-btn>
      </div>
      <v-list dense>
        <v-list-item
          link
          :to="{ name: 'user.calendar' }"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-calendar
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Календарь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.roles.length > 0 && user.roles.some((role) => role.slug === 'school')"
          link
          :to="{ name: 'school.dashboard' }"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-calendar-multiple
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Школа (календарь)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'user.profile' }"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-account
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Профиль</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.roles.length > 0 && user.roles.some((role) => role.slug === 'admin')"
          link
          :to="{ name: 'admin.strava.subscribes' }"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-check
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Сервисное меню</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      dark
      color="primary"
      height="52"
      flat
    >
      <v-btn
        v-if="authenticated"
        icon
        height="48"
        width="48"
        class="ml-0"
        @click.stop="drawer = !drawer"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.lgAndUp || !authenticated"
        text
        tile
        x-large
        active-class="before-d-none"
        class="px-3 mx-1 px-md-6 pl-md-5"
        :to="{ name: 'home' }"
      >
        <v-toolbar-title class="text-none">
          BlackLynx App
        </v-toolbar-title>
      </v-btn>
      <v-spacer />
      <div
        v-if="authenticated"
      >
        <v-menu
          offset-y
          tile
          left
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              color="secondary"
              text
              tile
              x-large
              class="text-subtitle-1 text-none pr-3 pl-4 pr-md-6"
              v-on="on"
            >
              <div
                style="max-width:55vw;letter-spacing:normal;"
              >
                {{ user.name }}
              </div>
              <v-avatar
                size="36"
                class="ml-3"
                :color="user.avatar ? 'primary' : 'secondary'"
              >
                <v-img
                  v-if="user.avatar"
                  :src="user.avatar"
                />
                <v-icon
                  v-else
                  color="primary"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list
            tile
            class="py-0"
          >
            <v-list-item
              @click="logout"
            >
              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
        v-else
      >
        <v-btn
          class="mr-2"
          text
          :to="{ name: 'login' }"
        >
          Вход
        </v-btn>
      </div>
    </v-app-bar>

    <v-main style="max-height: 100vh">
      <router-view :drawer="drawer" />
    </v-main>

    <app-snackbar />

    <the-confirmer />
  </v-app>
</template>

<script>
  import axios from 'axios'
  import { mapGetters, mapActions } from 'vuex'
  import AppSnackbar from '@/components/AppSnackbar/AppSnackbar'
  import TheConfirmer from '@/components/TheConfirmer'

  export default {
    name: 'App',

    components: {
      TheConfirmer,
      AppSnackbar
    },

    data: () => ({
      drawer: null,
    }),

    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      })
    },

    created: function () {
      let that = this;
      axios.interceptors.response.use(undefined, function (err) {
        return new Promise(function () { // resolve, reject) {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest && that.$route.name !== 'login' && that.$route.name !== 'register') {
            // if you ever get an unauthorized, logout the user
            //window.location.reload()
            that.logout()
            // you can also redirect to /login if needed !
          }
          throw err;
        });
      });
    },

    methods: {
      ...mapActions({
        logoutAction: 'auth/logout'
      }),

      async logout () {
        await this.logoutAction()
        await this.$router.replace({ name: 'login' })
      }
    }
  };
</script>

<style scoped lang="scss">
.app--navigation-drawer {
  z-index: 10;
}
</style>
