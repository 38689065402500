<template>
  <v-snackbar
    v-model="snackbarIsActive"
    tile
    :color="getSnackbarColor"
    timeout="3000"
  >
    <template>
      <div
        :class="getSnackbarContentColor + '--text'"
      >
        {{ getSnackbarContent }}
      </div>
    </template>

    <template #action>
      <v-btn
        :color="getSnackbarContentColor"
        text
        icon
        small
        @click="snackbarClose"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: "AppSnackbar",

    data: () => ({

    }),

    computed: {
      ...mapGetters('snackbar', [
        'getSnackbarIsActive',
        'getSnackbarColor',
        'getSnackbarContent',
        'getSnackbarContentColor'
      ]),
      snackbarIsActive: {
        get () {
          return this.getSnackbarIsActive;
        },
        set (val) {
          if (!val) {
            this.snackbarClose();
          }
        }
      },
    },

    methods: {
      ...mapActions('snackbar', [
        'snackbarClose'
      ])
    },
  }
</script>
