<template>
  <div
    v-if="getConfirmer.active"
  >
    <v-dialog
      v-model="getConfirmer.active"
      max-width="450"
    >
      <v-card>
        <v-card-title
          class="px-4"
        >
          {{ getConfirmer.title }}
        </v-card-title>
        <v-card-text
          v-if="getConfirmer.body"
          class="px-4 pb-3"
        >
          <div v-html="getConfirmer.body"></div>
        </v-card-text>
        <v-card-actions
          class="px-2"
        >
          <div
            class="row no-gutters"
          >
            <div
              class="col col-6 pt-0 pb-3 px-2"
            >
              <v-btn
                v-if="getConfirmer.accept"
                color="black"
                class="white--text confirmer--btn"
                depressed
                tile
                style="width: 100%; white-space: normal"
                @click.native="confirm"
              >
                {{ getConfirmer.accept }}
              </v-btn>
            </div>
            <div
              class="col col-6 pt-0 pb-3 px-2"
            >
              <v-btn
                depressed
                tile
                class="confirmer--btn"
                style="width: 100%;"
                @click.native="cancel"
              >
                {{ getConfirmer.decline }}
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'TheConfirmer',
  computed: {
    ...mapGetters('confirmer', [
      'getConfirmer',
    ]),
    confirmer () {
      return this.$store.state.confirmer
    }
  },
  methods: {
    ...mapActions('confirmer', [
      'confirmerAsk',
      'confirmerClose',
    ]),
    confirm () {
      this.getConfirmer.resolve(true);
      this.confirmerClose();
    },
    cancel () {
      this.getConfirmer.resolve(false);
      this.confirmerClose();
    }
  }
}
</script>

<style lang="scss">
.confirmer--btn {
  span {
    max-width: 100%;
  }
}
</style>
