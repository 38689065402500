import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.auth.authenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.state.auth.authenticated) {
    next()
    return
  }
  next('/login')
}

const ifAdmin = (to, from, next) => {
  if (store.state.auth.authenticated && store.state.auth.user && store.state.auth.user.roles.some((role) => role.slug === 'admin')) {
    next()
    return
  }
  next('/')
}

const ifSchool = (to, from, next) => {
  if (store.state.auth.authenticated && store.state.auth.user && store.state.auth.user.roles.some((role) => role.slug === 'school')) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'user.calendar'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/AuthLogin'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/AuthRegister'),
    beforeEnter: ifNotAuthenticated
  },

  // USER ROUTES
  {
    path: '/profile',
    name: 'user.profile',
    component: () => import(/* webpackChunkName: "user" */ '@/views/UserProfile'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/calendar',
    name: 'user.calendar',
    component: () => import(/* webpackChunkName: "user" */ '@/views/UserCalendar'),
    beforeEnter: ifAuthenticated
  },

  // SCHOOL ROUTES
  {
    path: '/school',
    name: 'school.dashboard',
    component: () => import(/* webpackChunkName: "school" */ '@/views/SchoolDashboard'),
    beforeEnter: ifSchool
  },
  {
    path: '/school/user',
    name: 'school.user',
    beforeEnter: ifSchool,
    redirect: {
      name: 'school.dashboard'
    },
  },
  {
    path: '/school/user/:id',
    name: 'school.user.calendar',
    component: () => import(/* webpackChunkName: "school" */ '@/views/SchoolUserCalendar'),
    props: true,
    beforeEnter: ifSchool,
  },

  // ADMIN ROUTES
  {
    path: '/strava/subscribes',
    name: 'admin.strava.subscribes',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminStravaSubscribes'),
    beforeEnter: ifAdmin,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
