import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

import './styles/app.scss';

// Moment.js
import moment from 'moment-timezone'
require('moment/locale/ru')


axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_URL

Vue.config.productionTip = false

Vue.use(require('vue-moment'), {
  moment
});

store.dispatch('auth/me')
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
